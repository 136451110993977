import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useApiClient } from '../hooks/useApiClient';
import { PixelStreamingContainer, LoadingScreen, CenteredLogo, TopBand } from '../styles/PixelStreamingComponentStyles';
import LoadingBar from './LoadingBar';

const PixelStreamingComponent = () => {
  const [loading, setLoading] = useState(true);
  const [player, setPlayer] = useState(null);
  const location = useLocation();
  const [projectName, setProjectName] = useState('');
  const [showLoadingBar, setShowLoadingBar] = useState(false); // Track loading bar visibility
  const [profileImage, setProfileImage] = useState(null); // Profile image state
  const apiClient = useApiClient(); // Initialize API client

  // Track whether the cursor is currently visible or hidden
  const [cursorVisible, setCursorVisible] = useState(true);

  // Timer reference to store the interval ID
  const [minuteTimer, setMinuteTimer] = useState(null);

  // Function to increment streaming minutes
  const addMinute = async () => {
    try {
      const response = await apiClient.post('/streaming/increment_minutes/');
      if (response.status === 200) {
        console.log('Streaming added 1 minute successfully');
      } else {
        console.error('Streaming Failed to add one minute');
      }
    } catch (error) {
      console.error('Error incrementing minutes:', error.response?.data?.message || 'Server error');
    }
  };

  useEffect(() => {
    // Fetch the user profile image
    const fetchProfileImage = async () => {
      try {
        const response = await apiClient.get('/user/');
        if (response.status === 200) {
          console.log('Fetched profile image:', response.data.profile_picture);
          setProfileImage(response.data.profile_picture); // Set profile image
        }
      } catch (error) {
        console.error('Error fetching profile image:', error);
      }
    };

    fetchProfileImage();
    const removeSvg = () => {
      const svgElement = document.querySelector(
        'svg[xmlns="http://www.w3.org/2000/svg"][viewBox="0 0 225 20"]'
      );
      if (svgElement) {
        svgElement.style.display = 'none';
      } else {
        // Retry after a short delay if the element isn't found
        setTimeout(removeSvg, 500);
      }
    };

    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const projectId = params.get('projectId');
    const configId = params.get('configId');

    // Set the project name
    setProjectName(`Project ${projectId}`);

    const script = document.createElement('script');
    const projectKey = '810844c1-41ca-4c84-a245-8b4ed7bb20d2'; // Hardcoded project key
    // Add cache-busting to the script URL to prevent caching issues
    script.src = `https://embed.arcanemirage.com/${projectKey}/e?v=${new Date().getTime()}`;
    script.defer = true;

    window.addEventListener('ArcanePlayerLoaded', () => {
      const loadedPlayer = window['ArcanePlayer'];
      setPlayer(loadedPlayer);

      // Setting up event listeners
      loadedPlayer.onReceiveEvent('CustomUIEventResponse', (response) => {
        console.log({ ArcaneResponse: response });
      });

      loadedPlayer.onPlayerEvent('loading', () => {
        setShowLoadingBar(true); // Show the loading bar when loading starts
        console.log('loading');
      });

      loadedPlayer.onPlayerEvent('ready', () => {
        setShowLoadingBar(false); // Hide the loading bar when ready
        console.log('ready');
        removeSvg();
        addMinute(); // Initial call

        // Start a timer to increment every 60 seconds
        const timerId = setInterval(addMinute, 60000);
        setMinuteTimer(timerId);

        // Start playing the stream
        loadedPlayer.play();

        // Add a delay before emitting the project data
        setTimeout(() => {
          loadedPlayer.emitUIEvent({
            event: 'ProjectConfigData',
            data: {
              token,
              projectId,
              configId,
            },
          });
          console.log('sent start ui event');
        }, 1500); // Delay in milliseconds

        // Add keydown event listener for Tab key
        const handleTabPress = (event) => {
          if (event.key === 'Tab') {
            event.preventDefault(); // Prevent default Tab behavior
            setCursorVisible((prevVisible) => {
              const newVisibility = !prevVisible;
              console.log('Cursor visibility toggled:', newVisibility);
              return newVisibility;
            });
          }
        };

        document.addEventListener('keydown', handleTabPress);

        // Cleanup the event listener on unmount
        return () => document.removeEventListener('keydown', handleTabPress);
      });

      // Handle 'disconnected' and 'exit' events
      const stopTimer = () => {
        setTimeout(() => {
          setShowLoadingBar(false); // Hide the loading bar
        }, 10000); // 10,000 milliseconds = 10 seconds
        clearInterval(minuteTimer); // Stop incrementing minutes
        console.log('Timer stopped');
      };

      loadedPlayer.onPlayerEvent('disconnected', stopTimer);
      loadedPlayer.onPlayerEvent('exit', stopTimer);

      // Handle 'afkTimedOut' event
      loadedPlayer.onPlayerEvent('afkTimedOut', () => {
        console.log('AFK timeout completed');
        stopTimer(); // Optionally hide the loading bar or perform other actions
      });
    });

    script.onload = () => {
      setLoading(false);
      if (window['initArcanePlayer']) {
        window['initArcanePlayer']();
      }
    };
    
    script.onerror = () => {
      console.error('Failed to load Arcane Mirage script');
      setLoading(false); 
    };

    document.body.appendChild(script);

    // Cleanup function to remove the script and clear the timer when the component unmounts
    return () => {
      clearInterval(minuteTimer); // Clear the timer on cleanup
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [location.search]);

  return (
    <PixelStreamingContainer>
      {/*<TopBand/>
      <CenteredLogo src={profileImage || '/logo.png'} alt="Profile Logo" /> */}
      {showLoadingBar && <LoadingBar isLoading={showLoadingBar} />}
      {loading && (
        <LoadingScreen>Loading...</LoadingScreen>
      )}
        <div
          id="arcane-player"
          data-project-id="3402"
          data-project-key="810844c1-41ca-4c84-a245-8b4ed7bb20d2"
          data-token="PVGhz_vJ8BPi"
          data-enable-events-passthrough={true}
          style={{ cursor: cursorVisible ? 'auto' : 'none', pointerEvents: 'auto', display: loading ? 'none': 'block' }}
        ></div>
    </PixelStreamingContainer>
  );
};

export default PixelStreamingComponent;
